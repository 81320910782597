import { render, staticRenderFns } from "./02shuzixiangcun.vue?vue&type=template&id=22009bc5&scoped=true&"
import script from "./02shuzixiangcun.vue?vue&type=script&lang=js&"
export * from "./02shuzixiangcun.vue?vue&type=script&lang=js&"
import style0 from "./02shuzixiangcun.vue?vue&type=style&index=0&id=22009bc5&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22009bc5",
  null
  
)

export default component.exports